<template>
  <v-row
    class="pa-0 pt-10"
    :class="{ 'flex-column-reverse': $vuetify.breakpoint.smAndDown }"
  >
    <v-col cols="12" md="6" class="d-flex align-center pa-0">
      <v-card class="mb-12 transparent elevation-0 w-100">
        <span
          class="white--text"
          :class="{
            'font-18': $vuetify.breakpoint.xsOnly,
            headline: $vuetify.breakpoint.smAndUp
          }"
          >1. Vali üritus</span
        >
        <div>
          <v-autocomplete
            v-model="event"
            :items="events"
            item-text="title"
            item-value="title"
            return-object
            label="Sisesta otsimiseks ürituse märksõna"
            no-data-text="Üritust ei leitud"
            solo
            color="white"
            class="mt-3 event-select"
            :loading="loading.state === true"
          >
            <template v-slot:append-item>
              <v-sheet>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Ei leidnud õiget? Lisa üritus
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="
                        event = null
                        addNewEventDialog = true
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-sheet>
            </template>
          </v-autocomplete>
          <v-card-actions
            class="pa-0 d-flex align-end flex-wrap"
            :style="{ marginTop: $vuetify.breakpoint.smAndUp ? '100px' : '' }"
            :class="{ 'flex-column-reverse': $vuetify.breakpoint.xsOnly }"
          >
            <NextBackButtons
              :first-button-disabled="false"
              :second-button-disabled="!selectedEvent"
              @firstButtonOnClick="$router.push('/')"
              @secondButtonOnClick="setStepperStep(2)"
            >
              <template v-slot:firstButton>
                <v-icon
                  left
                  size="20"
                  class="ma-0"
                  style="position:absolute; left:0px"
                  >mdi-chevron-left</v-icon
                >
                {{ $vuetify.lang.t('$vuetify.general.back') }}
              </template>
              <template v-slot:secondButton>
                {{ $vuetify.lang.t('$vuetify.general.next') }}
                <v-icon
                  left
                  size="20"
                  class="ma-0"
                  style="position:absolute; right:0px"
                >
                  mdi-chevron-right
                </v-icon>
              </template>
            </NextBackButtons>
          </v-card-actions>
          <add-event-dialog
            :visible="addNewEventDialog"
            @close="addNewEventDialog = false"
          />
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center pa-0">
      <v-img
        :src="require('@/assets/img/piletivahetus_ostamuuvaheta.png')"
        :lazy-src="require('@/assets/img/piletivahetus_ostamuuvaheta.png')"
        max-width="60%"
      >
      </v-img>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FirstStep',
  components: {
    AddEventDialog: () =>
      import(
        /* webpackChunkName: "AddEventDialog" */ '@/components/AddingTicket/AddEventDialog'
      ),
    NextBackButtons: () =>
      import(
        /* webpackChunkName: "NextBackButtons" */ '@/components/AddingTicket/NextBackButtons'
      )
  },
  data() {
    return {
      addNewEventDialog: false
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('events', ['events', 'selectedEvent']),
    event: {
      get() {
        return null
      },
      set(value) {
        this.$store.commit('events/setSelectedEvent', value)
      }
    }
  },
  async created() {
    if (this.events.length <= 0) {
      await this.getAllEvents()
    }
    this.$on('didAddNewEvent', event => {
      this.$store.commit('events/setSelectedEvent', event)
      this.$emit('didAddNewEventThroughDialog', event)
    })
  },

  methods: {
    ...mapActions('events', ['getAllEvents']),
    setStepperStep(step) {
      this.$store.commit('events/setStepperStep', step)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-list-item .v-list-item__title {
  max-width: 568px;
}
</style>
